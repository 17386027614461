<template>
  <SuccessBanner
    :key="key"
    :msg="successMsg"
    :active="activeBanner"
    @closeBanner="activeBanner = false"
    :isSuccess="isSuccess"
  />
  <div class="p-10">
    <FormTemplate
      :submit="false"
      formTitle="PBX 3cx Host"
      :form="form1"
      :showFormDefault="true"
      v-model:customer_file_id="formData.customer_file_id"
      v-model:code="formData.code"
      v-model:host_name="formData.host_name"
      v-model:ip_address="formData.ip_address"
      v-model:web_username="formData.web_username"
      v-model:web_password="formData.web_password"
      v-model:ssh_username="formData.ssh_username"
      v-model:ssh_password="formData.ssh_password"
      v-model:ssh_key="formData.ssh_key"
      v-model:database_name="formData.database_name"
      v-model:database_username="formData.database_username"
      v-model:database_password="formData.database_password"
      v-model:licence_key="formData.licence_key"
      v-model:licence_type="formData.licence_type"
      v-model:licence_activation_date="formData.licence_activation_date"
      v-model:licence_renewal_date="formData.licence_renewal_date"
      v-model:sbc_aliases="formData.sbc_aliases"
      v-model:comment="formData.comment"
      v-model:active="formData.active"
    />
    <!-- <FormTemplate
      :submit="false"
      formTitle="CSV"
      :form="form2"
      v-model:csv_location="formData.csv_location"
      v-model:make_csv_data="formData.make_csv_data"
      v-model:csv_data_made_at="formData.csv_data_made_at"
      v-model:fetch_csv_data="formData.fetch_csv_data"
      v-model:csv_data_fetched_at="formData.csv_data_fetched_at"
      v-model:csv_data_frequency="formData.csv_data_frequency"
    /> -->
    <FormTemplate
      :submit="false"
      formTitle="Backup"
      :form="form3"
      v-model:backups_location="formData.backups_location"
      v-model:fetch_backups="formData.fetch_backups"
      v-model:backups_fetched_at="formData.backups_fetched_at"
      v-model:backups_frequency="formData.backups_frequency"
      v-model:backups_host="formData.backups_host"
      v-model:backups_host_username="formData.backups_host_username"
      v-model:backups_host_password="formData.backups_host_password"
    />
    <FormTemplate
      :submit="false"
      formTitle="CDR"
      :form="form4"
      v-model:cdr_location="formData.cdr_location"
      v-model:fetch_cdr="formData.fetch_cdr"
      v-model:cdr_fetched_at="formData.cdr_fetched_at"
      v-model:push_cdr="formData.push_cdr"
      v-model:cdr_pushed_at="formData.cdr_pushed_at"
      v-model:cdr_frequency="formData.cdr_frequency"
    />
    <FormTemplate
      @onSubmit="onSubmit('Customer created successfully.')"
      :submit="true"
      formTitle="Recordings"
      :form="form5"
      v-model:recordings_location="formData.recordings_location"
      v-model:fetch_recordings="formData.fetch_recordings"
      v-model:recordings_fetched_at="formData.recordings_fetched_at"
      v-model:push_recordings="formData.push_recordings"
      v-model:recordings_pushed_at="formData.recordings_pushed_at"
      v-model:recordings_frequency="formData.recordings_frequency"
    />
  </div>
</template>

<script>
import axios from "axios";

import FormTemplate from "../components/FormTemplate.vue";
import SuccessBanner from "../components/SuccessBanner.vue";

export default {
  components: {
    FormTemplate,
    SuccessBanner,
  },
  data() {
    return {
      successMsg: "",
      activeBanner: false,
      isSuccess: true,
      key: false,
      formData: {
        active: true,
        customer_file_id: null,
        code: null,
        host_name: "string",
        ip_address: null,
        web_username: null,
        web_password: null,
        ssh_username: "phonesystem",
        ssh_password: null,
        ssh_key: null,
        database_name: "database_single",
        database_username: "phonesystem",
        database_password: null,
        licence_key: null,
        licence_type: null,
        licence_activation_date: null,
        licence_renewal_date: null,
        sbc_aliases: null,
        backups_location: "var/lib/3cxpbx/Instance1/Data/Backups",
        fetch_backups: false,
        backups_fetched_at: null,
        backups_frequency: 1440,
        backups_host: null,
        backups_host_username: null,
        backups_host_password: null,
        cdr_location: "var/lib/3cxpbx/Instance1/Data/Logs/CDRLogs",
        fetch_cdr: false,
        cdr_fetched_at: null,
        push_cdr: false,
        cdr_pushed_at: null,
        cdr_frequency: 1440,
        csv_location: "var/lib/3cxpxb/Instance1/Data/Logs/Csv",
        make_csv_data: false,
        csv_data_made_at: null,
        fetch_csv_data: false,
        csv_data_fetched_at: null,
        csv_data_frequency: 1440,
        recordings_location: "var/lib/3cxpbx/Instance1/Data/Recordings",
        fetch_recordings: false,
        recordings_fetched_at: null,
        push_recordings: false,
        recordings_pushed_at: null,
        recordings_frequency: 1440,
        comment: null,
      },
      form1: [
        {
          name: "Customer file",
          label: "customer_file_id",
          type: "input",
          required: false,
        },
        { name: "code", label: "code", type: "input", required: false },
        {
          name: "host name",
          label: "host_name",
          type: "input",
          required: false,
        },
        {
          name: "IP Address",
          label: "ip_address",
          type: "input",
          required: false,
        },
        {
          name: "Web username",
          label: "web_username",
          type: "input",
          required: false,
        },
        {
          name: "Web password",
          label: "web_password",
          type: "input",
          required: false,
        },
        {
          name: "SSH Username",
          label: "ssh_username",
          type: "input",
          required: false,
        },
        {
          name: "SSH Password",
          label: "ssh_password",
          type: "input",
          required: false,
        },
        { name: "SSH Key", label: "ssh_key", type: "input", required: false },
        {
          name: "Database name",
          label: "database_name",
          type: "input",
          required: false,
        },
        {
          name: "Database username",
          label: "database_username",
          type: "input",
          required: false,
        },
        {
          name: "database password",
          label: "database_password",
          type: "input",
          required: false,
        },
        {
          name: "licence key",
          label: "licence_key",
          type: "input",
          required: false,
        },
        {
          name: "licence type",
          label: "licence_type",
          type: "input",
          required: false,
        },
        {
          name: "licence renewal date",
          label: "licence_renewal_date",
          type: "input",
          required: false,
        },
        {
          name: "SBC Aliases",
          label: "sbc_aliases",
          type: "input",
          required: false,
        },
        { name: "comment", label: "comment", type: "input", required: false },
        {
          name: "active",
          label: "active",
          type: "checkbox",
          required: false,
          value: 1,
        },
      ],
      form2: [
        {
          name: "CSV Location",
          label: "csv_location",
          type: "input",
          required: false,
        },
        {
          name: "make csv data",
          label: "make_csv_data",
          type: "checkbox",
          value: 0,
          required: false,
        },
        {
          name: "CSV Made at",
          label: "csv_data_made_at",
          type: "input",
          required: false,
        },
        {
          name: "Fetch CSV",
          label: "fetch_csv_data",
          type: "checkbox",
          value: 0,
          required: false,
        },
        {
          name: "CSV Fetched at",
          label: "csv_data_fetched_at",
          type: "input",
          required: false,
        },
        {
          name: "CSV Frequency",
          label: "csv_data_frequency",
          type: "input",
          required: false,
        },
      ],
      form3: [
        {
          name: "Backups Location",
          label: "backups_location",
          type: "input",
          required: false,
        },

        {
          name: "backups_fetched_at",
          label: "backups_fetched_at",
          type: "input",
          required: false,
        },
        {
          name: "Backups frequency",
          label: "backups_frequency",
          type: "input",
          required: false,
        },
        {
          name: "backups host",
          label: "backups_host",
          type: "input",
          required: false,
        },
        {
          name: "backups host username",
          label: "backups_host_username",
          type: "input",
          required: false,
        },
        {
          name: "backups host password",
          label: "backups_host_password",
          type: "input",
          required: false,
        },
        {
          name: "Fetch Backups",
          label: "fetch_backups",
          type: "checkbox",
          value: 0,
          required: false,
        },
      ],
      form4: [
        {
          name: "CDR location",
          label: "cdr_location",
          type: "input",
          required: false,
        },

        {
          name: "cdr_fetched_at",
          label: "cdr_fetched_at",
          type: "input",
          required: false,
        },

        {
          name: "cdr_pushed_at",
          label: "cdr_pushed_at",
          type: "input",
          required: false,
        },
        {
          name: "CDR frequency",
          label: "cdr_frequency",
          type: "input",
          required: false,
        },
        {
          name: "push CDR",
          label: "push_cdr",
          type: "checkbox",
          value: 0,
          required: false,
        },
        {
          name: "fetch cdr",
          label: "fetch_cdr",
          value: 0,
          type: "checkbox",
          required: false,
        },
      ],
      form5: [
        {
          name: "recordings location",
          label: "recordings_location",
          type: "input",
          required: false,
        },

        {
          name: "recordings_fetched_at",
          label: "recordings_fetched_at",
          type: "input",
          required: false,
        },

        {
          name: "recordings_pushed_at",
          label: "recordings_pushed_at",
          type: "input",
          required: false,
        },
        {
          name: "recordings frequency",
          label: "recordings_frequency",
          type: "input",
          required: false,
        },
        {
          name: "fetch recordings",
          label: "fetch_recordings",
          type: "checkbox",
          value: 0,
          required: false,
        },
        {
          name: "push recordings",
          label: "push_recordings",
          type: "checkbox",
          value: 0,
          required: false,
        },
      ],
    };
  },
  methods: {
    onSubmit(msg) {
      let data = this.formData;
      console.log(data);
      //   axios
      //     .post(`${this.$cookie.getCookie("API")}/api/v1/customers`, data)
      //     .then((res) => {
      //       console.log(res);
      //       this.activeBanner = true;
      //       this.key = !this.key;
      //       this.successMsg = msg;
      //       this.isSuccess = "Sorry, something went wrong.";
      //     })
      //     .catch((error) => {
      //       this.errorHandling(error);
      //     });
    },
  },
};
</script>

<style></style>
