<template>
  <form
    @submit.prevent="getSelectors()"
    class="space-y-8 divide-y divide-gray-200 bg-white shadow-md pr-6 pl-6 pb-2 rounded"
  >
    <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
      <div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
        <div class="flex justify-between">
          <h3
            class="text-lg leading-6 font-medium body__header--text capitalize"
          >
            {{ formTitle }}
          </h3>
          <a href="javascript:void(0)" @click="showForm = !showForm">
            <svg
              v-if="!showForm"
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
            <svg
              v-if="showForm"
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </a>
        </div>
        <div class="space-y-6 sm:space-y-5" v-show="showForm">
          <div
            class="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
          >
            <div v-for="data in form" :key="data.label">
              <div>
                <label
                  :for="data.label"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize"
                >
                  {{ data.name }}
                </label>
              </div>
              <div :class="[`mt-1 sm:mt-0`]" v-if="data.type === 'input'">
                <input
                  @input="$emit(`update:${data.label}`, $event.target.value)"
                  type="text"
                  :required="data.required"
                  :name="data.label"
                  :id="data.label"
                  :autocomplete="data.label"
                  class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div class="mt-1 sm:mt-0" v-if="data.type === 'select'">
                <select
                  @change="$emit(`update:${data.label}`, $event.target.value)"
                  :id="data.label"
                  :required="data.required"
                  :name="data.label"
                  :autocomplete="data.label"
                  class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                >
                  <option
                    v-for="option in data.options"
                    :key="option.id"
                    :value="option.id"
                    >{{ option.name }}</option
                  >
                </select>
              </div>
              <div
                class="relative flex items-start"
                v-if="data.type === 'checkbox'"
              >
                <div class="flex items-center mt-3">
                  <input
                    @input="
                      $emit(`update:${data.label}`, $event.target.checked)
                    "
                    :checked="data.value"
                    :required="data.required"
                    :name="data.label"
                    :id="data.label"
                    :value="data.value"
                    type="checkbox"
                    class="h-4 w-4 primary_text border-gray-300 rounded"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-5" v-show="submit">
      <div class="flex justify-end">
        <!-- <button
              type="button"
              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button> -->
        <button
          type="submit"
          class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md body__button text-white"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: ["form", "formTitle", "submit", "showFormDefault", "formData"],
  components: {},
  data() {
    return {
      showForm: false,
    };
  },
  methods: {
    showOrHideForm() {
      this.showForm = !this.showForm;
      this.$forceUpdate();
    },
    getSelectors() {
      let data = this.formData;
      for (let index = 0; index < this.form.length; index++) {
        if (this.form[index].type === "select") {
          data[`${this.form[index].label}`] = document.querySelector(
            `[name='${this.form[index].label}']`
          ).selectedIndex;
        }
        if (this.form[index].type === "checkbox") {
          data[`${this.form[index].label}`] = document.querySelector(
            `[name='${this.form[index].label}']`
          ).checked;
        }
      }
      this.$emit("dataFromTemplate", data);
    },
  },
  mounted() {
    this.showFormDefault
      ? (this.showForm = this.showFormDefault)
      : (this.showForm = this.showForm);
  },
};
</script>

<style></style>
